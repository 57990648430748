.info-modal-wrapper .modal-header {
  border-radius: 19px 19px 0 0;
}

.info-modal-wrapper .modal-body {
  word-break: break-all;
}

.info-modal-wrapper .modal-info {
  background-color: #2957af;
  color: #fff;
}

.info-modal-wrapper .modal-success {
  background-color: #09ab09;
  color: #fff;
}

.info-modal-wrapper .modal-error {
  background-color: #b30000;
  color: #fff;
}

.info-modal-wrapper .modal-warning {
  background-color: #f5c414;
  color: #fff;
}

.info-modal-wrapper .modal-basic {
  background-color: transparent;
}

.info-modal-icon {
  width: 35px;
  height: 35px;
  border-radius: 25px;
  padding: 10px;
  fill: #fff;
  margin-right: 10px;
}

.info-modal-wrapper .modal-body {
  word-break: break-word;
}

.info-modal-wrapper .modal-body .delete-webhook {
  word-break: break-all;
}

.modal-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000029;
  border-radius: 20px;
  border: 0;
}

.modal-body {
  display: flex;
  padding: 2rem 3rem;
  padding-bottom: 0;
  align-items: center;
}

.modal-body .timezone-picker,
.modal-body .rc-time-picker {
  margin-top: 0;
  margin-bottom: 0;
}

.modal-body select,
.modal-body .rc-time-picker {
  -webkit-appearance: none;
}

.modal-header .close {
  position: absolute;
  right: 1rem;
}

.modal-body .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}
.modal-footer {
  border: 0;
}

.pricing-tiers-modal > .modal-dialog {
  max-width: 1200px;
}

.company-info-modal > .modal-dialog,
.payment-modal > .modal-dialog {
  max-width: 760px;
}

.pricing-tiers-modal > .modal-dialog .step-progress-wrapper,
.company-info-modal > .modal-dialog .step-progress-wrapper {
  margin: 0 auto 80px;
  width: 600px;
}

.pricing-tiers-modal > .modal-dialog .new-tabs-design > p,
.pricing-tiers-modal > .modal-dialog .new-tabs-design > span {
  font-size: 24px;
  font-weight: normal;
  color: #535465;
}

.pricing-tiers-modal > .modal-dialog .new-tabs-design > span {
  font-size: 14px;
}

.pricing-tiers-modal > .modal-dialog .new-tabs-design > span > span {
  color: #74b9ff;
  cursor: pointer;
}

.pricing-tiers-modal > .modal-dialog .new-tabs-design > .pricing-tier-cards {
  display: flex;
  justify-content: space-between;
  margin: 32px auto 36px;
}

.pricing-tiers-modal > .modal-dialog .modal-footer {
  margin: auto;
  max-width: 500px;
}

.pricing-tiers-modal > .modal-dialog .modal-footer .btn.primary-bg:disabled,
.pricing-tiers-modal > .modal-dialog .modal-footer .btn.primary-bg[disabled] {
  cursor: not-allowed;
  filter: brightness(1.3);
}

.pricing-tiers-modal > .modal-dialog .two-step-progress .step-progress-wrapper {
  margin: 0 auto 80px;
  width: 250px;
}

.company-info-modal > .modal-dialog .new-tabs-design > .row {
  margin-top: -0.5rem;
  margin-bottom: -0.7rem;
}

.seat-info-modal > .modal-dialog {
  max-width: 800px;
}

.seat-info-modal .step-progress-wrapper {
  margin: 0 auto 80px;
  width: 404px;
}
.seat-info-modal .two-step-progress .step-progress-wrapper {
  margin: 0 auto 80px;
  width: 250px;
}

.email-provider-connected-modal > .modal-dialog {
  max-width: 1250px;
}

.payment-modal .form-label,
.payment-modal .accordion-button,
.payment-modal p {
  font-weight: 500 !important;
}

.payment-modal .two-step-progress .step-progress-wrapper {
  margin: 0 auto 80px;
  width: 250px;
}

.payment-modal .pricing-notice {
  display: block;
  width: 100%;
  padding-top: 5px;
  color: #98ABC1;
  font-size: 10px;
  text-align: center;
}

.payment-success .payment-success-body {
  display: flex;
  flex-direction: column;
}

.payment-success .payment-success-body p {
  text-align: center;
}

.payment-success .payment-success-body img {
  object-fit: contain;
  object-position: center;
  margin: 32px auto;
}

.payment-success button.btn.transparent-btn {
  color: #74b9ff !important;
}

.payment-success .button-text {
  font-size: 18px !important;
}

.subscription-modal p {
  font-weight: normal;
}

.billing-modal p,
.billing-modal label {
  color: #535465 !important;
  font-weight: normal !important;
}

.billing-modal .modal-footer {
  padding-top: 2rem;
}

.change-subscription-card-modal .modal-footer,
.change-company-info-modal .modal-footer {
  padding: 1rem 4rem;
}

.change-company-info-modal > .modal-dialog {
  max-width: 760px;
}

.notification-emoji-modal .modal-body {
  padding: 0 !important;
}

.notification-emoji-modal .modal-body > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 3rem;
}

.notification-emoji-modal .modal-body span img {
  margin-bottom: 16px;
}

.notification-emoji-modal .modal-body .close-button {
  position: absolute;
  top: -10px;
  right: -10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #74b9ff;
  color: white;
  cursor: pointer;
}

.add-new-leads .custom-file-label {
  max-height: 100%;
}

.upgrade-pricing-tier-modal .modal-body > span,
.transfer-seats-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.setup-webhook-modal .modal-body .form-check-label {
  font-size: 15px;
}

.add-account-step2-modal .modal-dialog,
.add-account-step3-modal .modal-dialog,
.add-account-step4-modal .modal-dialog {
  max-width: 550px;
}

.add-account-step4-modal .timezone-picker > input {
  font-family: "Montserrat", cursive !important;
}

.add-account-step3-modal .form-group {
  margin-bottom: 1.5rem;
}

.add-account-step3-modal .linkedin-subscription {
  margin-bottom: 1.5rem !important;
}

.add-account-step3-modal .input-group-wrapper {
  margin-bottom: 1.5rem !important;
}

.email-integration-modal > .modal-dialog {
  max-width: 95%;
}

.skylead2-modal .modal-dialog {
  max-width: 800px !important;
  position: relative;
}

.skylead2-modal .new-tabs-design {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.skylead2-modal .new-tabs-design > .close-button {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #74b9ff;
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.skylead2-modal h1 {
  text-align: center;
  font-size: 26px;
}

.skylead2-modal .new-tabs-design .text,
.skylead2-modal .new-tabs-design button,
.skylead2-modal .new-tabs-design h1 {
  margin-top: 20px !important;
}

.skylead2-modal .new-tabs-design .text p {
  text-align: center;
  margin-top: 10px;
}

.download-invoices-modal > .modal-dialog {
  max-width: 1100px;
}

.back-lead-to-campaign-modal > .modal-dialog {
  max-width: 90%;
}

.filter-leads-by-status-modal > .modal-dialog {
  max-width: 1100px;
}

.suggested-templates-modal > .modal-dialog {
  max-width: 900px;
}
.maxZIndex {
  z-index: 1050;
}
