.loading-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 10000000;
  margin: 0;
}

.loading-page-inner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
