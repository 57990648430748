.loader-container {
  display: flex;
  flex-direction: column;
  width: max-content;
  justify-content: center;
  align-items: center;
  &.fullWidth {
    width: 100%;
  }
}

.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  svg {
    transform: scale(2) !important; // this is hack for zooming svg into container
  }

  .loading-text {
    font-weight: 600;
    margin-left: 12px;
  }
}
