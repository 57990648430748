/* global */
/* ------ */
* {
  margin: 0;
  padding: 0;
  outline: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: "Montserrat";
  background-color: #eaeef2;
}
svg {
  fill: currentColor;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none !important;
}
/* end global */

/* typography */
/* ---------- */
p,
h1,
h2,
h3 {
  margin: 0;
}
h1 {
  padding: 0;
  color: currentColor;
}
h2 {
  font-weight: 500;
  font-size: 22px;
}
h2.white {
  color: white;
}
h3.subtitle {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
.subtitle.black {
  color: black;
}
.subtitle.f-size-16 {
  font-size: 16px;
}
.f-size-16 {
  font-size: 16px;
}
/* end typography */

/* width height style */
.flex-1 {
  flex: 1;
}
.w-8 {
  width: 8%;
}
.w-10 {
  width: 10%;
}
/* ------------------ */
.w-38 {
  width: 38%;
}
.w-62 {
  width: 62%;
}
/* end width height */

/* global elements style */
/* --------------------- */
section {
  margin-top: 40px;
}
section.section--mofifier {
  border-radius: 15px;
}
.padding-left-50 {
  padding-left: 50px !important;
}
.info-icon {
  width: 25px;
  height: 25px;
  box-sizing: content-box;
  padding: 5px;
}
.icon-circle {
  border-radius: 50px;
}
.padding-right {
  padding-right: 40px;
}
.padding-right--modifier {
  padding-right: 60px;
}
.subtitle-wrapper {
  padding: 48px 0;
  border-radius: 15px 15px 0 0;
  background-color: #282828;
  box-shadow: 0px 3px 6px #4a75a17a;
  position: relative;
}
.subtitle-wrapper.subtitle-campaigns--modifier {
  padding: 20px 0;
}
.subtitle-wrapper.subtitle-wrapper--modifier {
  padding: 32px 0;
}
input {
  background-color: transparent;
  border: 0;
}
a {
  color: currentColor;
}
a:hover {
  text-decoration: none;
  color: currentColor;
}
.wrapper-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 80px;
}
.wrapper-buttons.buttons--modifier {
  margin: 0;
  padding: 25px 26px;
}
.wrapper-buttons.buttons--modifier .buttons-tab {
  padding: 10px 20px;
  margin: 0;
  margin-bottom: 1rem;
}

.wrapper-buttons .buttons-tab {
  // padding: 10px 24px;
  color: white;
  border-radius: 50px;
  /* background-color: #828282; */
  transition: 0.3s;
  margin: 0 30px 24px 0;
  cursor: pointer;
}
.user-info {
  position: absolute;
  top: 3.8%;
  right: 3.8%;
}
.user-info svg {
  width: 20px;
  height: 20px;
}
.not-yet {
  padding-top: 32px !important;
}

.dark-text {
  color: #232b66;
}

.theme-1 .buttons-tab {
  background-color: #98abc1;
}
.theme-2 .buttons-tab {
  background-color: #98abc1;
}

.theme-2 .table-head-wrapper,
.theme-2 .table-head-wrapper .table-head-tr,
.theme-2 .table-head-left,
.theme-2 .table-head-right {
  background-color: #1b1d48;
}

.theme-2 .secondary-bg {
  background-color: #536df2 !important;
}
.theme-2 .secondary-color {
  color: #fff !important;
}
.theme-2 .highlight-bg {
  background-color: #536df2;
}
// .theme-2 .separator-bg {
//   background-color: #fff;
// }
.theme-2 .highlight-color {
  color: #fff;
}
.theme-2 input[type="checkbox"].switch:checked + div > div {
  background: #1b1d48;
}
.theme-2 input[type="checkbox"].switch:checked + div {
  background-color: #1b1d487d;
}
.theme-2 .primary-bg {
  background-color: #1b1d48 !important;
}

.theme-2 .primary-color {
  color: #1b1d48;
}
.theme-2 .chat-start-bg {
  background-color: #536df2 !important;
}
.theme-2 .wrapper-buttons .buttons-tab:hover {
  background-color: #536df2;
}
.theme-2 .sidebar-menu {
  background-color: #1b1d48;
}
.theme-2 .sidebar-item-active {
  background: linear-gradient(1.22deg, #536df2 0%, #3823ec 100%);
}
.theme-2 .campaign-name-column:hover {
  color: #1b1d48;
}
.theme-2 .input-range__track--active {
  background: linear-gradient(346.83deg, #536df2 0%, #1b1d48 100%);
}

.theme-2 .input-range__slider {
  background-color: #536df2 !important;
}
.theme-2 .input-range__slider-container:nth-child(2) .input-range__slider {
  background-color: #1b1d48 !important;
}
/* end theme 2 */

body#app-content-body.theme-1
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-2
  .input-range-critical
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body.theme-1 .input-range-critical .input-range__slider,
body#app-content-body.theme-2 .input-range-critical .input-range__slider {
  background-color: rgb(238, 5, 53) !important;
}

body#app-content-body.theme-1 .input-range-critical .input-range__track--active,
body#app-content-body.theme-2 .input-range-critical .input-range__track--active {
  background: rgb(238, 5, 53) !important;
}

body#app-content-body .input-range-warmup .input-range__track--active,
body#app-content-body
  .input-range-warmup
  .input-range__slider-container:nth-child(2)
  .input-range__slider,
body#app-content-body .input-range-warmup .input-range__slider {
  background: #f5c414 !important;
}

/* Subtitle list */
/* -------- */
.date-row {
  min-width: 11%;
}
.name-row {
  min-width: 30%;
}
.date-row.modifier {
  min-width: 40%;
  display: flex;
}
.w-20 {
  min-width: 20% !important;
}
.w-40 {
  min-width: 40% !important;
}
.w-60 {
  min-width: 60% !important;
}
.icons-rows {
  width: 30%;
}
.icons-rows.modifier {
  min-width: 40%;
}
.color {
  color: #1b1d48 !important;
}
.label {
  color: #1b1d48;
  font-size: 12px;
}
.icons-rows .check {
  color: #1c1b1b;
}
.icons-rows .times {
  color: #bdbdbd;
}
.icons-rows span {
  min-width: 25%;
}
.icons-rows li {
  min-width: 25%;
  position: relative;
}
.icons-rows li:hover .desc-text {
  opacity: 1;
}
.icons-rows li .desc-text {
  min-width: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  padding: 4px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.6s;
  transform: translateY(-100%);
  border: 1px solid #282828;
}

.text-rows {
  width: 59%;
}
.text-rows.modifier {
  width: 60%;
}
.text-rows.modifier1 {
  width: 60%;
}
.text-rows.modifier li {
  width: 25%;
}
.text-rows.modifier1 li {
  min-width: 25%;
}
.text-rows li {
  width: 16.5%;
  padding: 0 4px;
}
.radius-50 {
  border-radius: 50px;
}
.circle {
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
}
.dark-bg {
  background-color: #7282a0;
  padding: 8px 20px;
}
.light-bg {
  background-color: #e0e0e0;
  padding: 10px;
}

.wrp-campaigns-list {
  border-radius: 0 0 15px 15px;
}
.campaigns-list {
  border-bottom: 1px solid rgba(128, 128, 128, 0.46);
}
.campaigns-list:last-child {
  border-bottom: none;
  /* padding-bottom: 40px !important; */
}

*:focus {
  outline: none !important;
  box-shadow: none !important;
}
.Toastify__toast {
  padding: 10px;
}

.form-control.is-valid,
.was-validated .form-control:valid,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #ced4da;
  background: #fff;
}

.crisp-client {
  z-index: 5000;
  position: relative;
}

.dropdown-menu a:hover {
  color: #74b9ff;
}

*::-webkit-scrollbar {
  width: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.dropdown-delete-button:hover {
  background-color: rgba(238, 5, 53, 0.15) !important;
}

button:hover {
  opacity: 0.7;
}

body#app-content-body.theme-1 button.btn.primary-bg.add-leads-button {
  border-radius: 12px !important;
  padding: 5px 10px;
  .button-text {
    font-size: 14px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 6px dashed;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}

.caret.caret-down {
  content: "";
  border-top: 0;
  border-bottom: 6px dashed;
}

.tooltip {
  transition: 0.4s;
  border-radius: 5px;
  background-color: #282828;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 4px;
  color: white;
  top: 0;
  transform: translateY(-110%);
  display: none;
}

.hover-tooltip .tooltip {
  display: none;
}
.hover-tooltip:hover .tooltip {
  opacity: 1;
  display: block;
}

.hover-tooltip:not(.no-tooltip-caret) .tooltip:before {
  content: "";
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 16px solid #2f2f2f;
  position: absolute;
  bottom: -15px;
}
/* .wrp-tooltip:hover .tooltip {
  opacity: 1;
} */

.wrapper-buttons .buttons-tab.buttons-tab-active {
  background: blue;
}

.wrp-tooltip button:hover + .tooltip,
.wrp-tooltip .form-group:hover + .tooltip {
  display: block;
  opacity: 1;
}

.search-icon {
  width: 30px;
  height: 20px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
}

.logout-link {
  cursor: pointer;
}

.theme-2 .logout-link:hover {
  color: #536df2;
}

svg.back-lead-icon {
  width: 40px;
  height: 40px;
}

.timezone-picker {
  height: 38px;
}

.timezone-picker input {
  padding: 0 !important;
  border: 0 !important;
}

.form label {
  font-weight: bold;
  color: #000;
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

iframe {
  border: 0;
  width: 100%;
  height: max-content;
}
button.btn .button-text {
  font-weight: 400 !important;
}

.create-tag-icon-selected {
  display: flex;
  width: 12px;
  height: 12px;
  fill: #fff;
}

.add-image-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

button.btn.add-image-btn {
  float: right;
}

.delete-image-template {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  color: #fff;
  background-color: #74b9ff;
  border-radius: 15px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
}

.template-image-item:hover ~ .delete-image-template,
.delete-image-template:hover {
  display: flex;
}

.icon-step-close-image {
  width: 9px;
  height: 9px;
  color: white;
}

.delete-item-bin-container {
  color: #eb2158;
  font-weight: bold;
}

.font-semi-bold {
  font-weight: 500;
}

.step-2-campaigns {
  padding: 20px 60px;
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}
.create-campaign-info-buttons {
  display: flex;
  justify-content: flex-end;
}

.wrp-tooltip {
  position: relative;
}

.html-message-icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: unset;
}

.email-provider-connect-wrapper .email-integrations-wrapper .email-integration-wrapper {
  border: 1px solid #e1e7f1;
  padding: 58px 5px 58px 20px;
  margin: 17px;
  border-radius: 4px;
}

.email-provider-connect-wrapper .email-integration-wrapper .btn.btn-danger {
  width: 181px;
}

.custom-toolbar-caret {
  position: absolute;
  bottom: -9px;
  border-width: 9px 9px 0px 9px;
  border-style: solid;
  border-color: #e1eaf5 transparent;
}

.custom-toolbar-caret:before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: -12px;
  border-top: 12px solid #e1eaf5;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}

.custom-toolbar-caret:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -11px;
  border-top: 11px solid #fff;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
}

.helper-tooltip {
  position: relative;
  display: inline-block;
}

.helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 135px;
  background-color: #282828;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1100;
  bottom: 115%;
  left: 60%;
  margin-left: -67px;
  font-size: 13px;
}

.helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text {
  visibility: hidden;
  width: 225px;
  background-color: #ffffff;
  color: #080808;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -84px;
  left: 16%;
  margin-left: -67px;
  font-size: 13px;
  border: 1px solid #e1eaf5;
  text-align: start;
  padding: 9px;
  cursor: pointer;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.white-helper-tooltip .helper-hover-text::before {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 109px;
  border-width: 9px 9px 0px 9px;
  border-style: solid;
  border-color: #e1eaf5 transparent;
}

.white-helper-tooltip .helper-hover-text::after {
  content: "";
  position: absolute;
  top: 85px;
  left: 113px;
  margin-left: -3px;
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.white-helper-tooltip:hover .helper-hover-text {
  visibility: visible;
}

.ql-container.ql-snow .ql-tooltip {
  left: 0 !important;
  right: unset !important;
}

.noselect-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
